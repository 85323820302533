'use client';

import { cva, type VariantProps } from 'class-variance-authority';
import { createContext, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const buttonGroupVariants = cva('inline-flex items-center', {
  variants: {
    size: {
      xs: '',
      sm: '',
      md: '',
      lg: '',
      xl: '',
    },
    full: {
      true: 'grid grid-flow-col',
    },
    variant: {
      default: '',
      solid: '',
      outline: '',
      light: '',
      subtle: '',
      link: '',
    },
    disabled: {
      true: '',
    },
    unstyled: {
      true: 'gap-x-2 sm:gap-x-2.5',
      false:
        '[&>*:active]:transform-none [&>*:focus-visible]:z-10 [&>*:not(:first-child)]:rounded-l-none [&>*:not(:last-child)]:rounded-r-none [&>*]:shadow-none',
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      unstyled: false,
      className: 'shadow-sm [&>*:not(:first-child)]:border-l-0',
    },
    {
      variant: 'solid',
      unstyled: false,
      className: 'shadow-sm [&>*:not(:first-child)]:border-l-white',
    },
    {
      variant: 'outline',
      unstyled: false,
      className: '[&>*:not(:first-child)]:border-l-0',
    },
    {
      variant: 'light',
      unstyled: false,
      className: '[&>*:not(:first-child)]:border-l-white',
    },
  ],
});

type ButtonGroupContext = VariantProps<typeof buttonGroupVariants>;

export interface ButtonGroupProps
  extends VariantProps<typeof buttonGroupVariants>,
    React.HTMLAttributes<HTMLDivElement> {}

export const ButtonGroupContext = createContext<ButtonGroupContext>({});

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  (
    { size, full, variant, disabled, unstyled = false, hidden, className, children, ...restProps },
    ref,
  ) => {
    if (hidden || !children) {
      return null;
    }

    const value = {
      size,
      full,
      variant,
      disabled,
    };

    const mergedClassName = twMerge(
      buttonGroupVariants({
        size,
        full,
        variant,
        disabled,
        unstyled,
        className,
      }),
    );

    return (
      <div ref={ref} className={mergedClassName} {...restProps}>
        <ButtonGroupContext.Provider value={value}>{children}</ButtonGroupContext.Provider>
      </div>
    );
  },
);

ButtonGroup.displayName = 'ButtonGroup';
