export enum PaymentStageName {
  DEPOSIT = 'DEPOSIT',
  BASE = 'BASE',
  FRAME = 'FRAME',
  LOCK_UP = 'LOCK_UP',
  FIXING = 'FIXING',
  PRACTICAL_COMPLETION = 'PRACTICAL_COMPLETION',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

export enum PaymentStatus {
  SCHEDULED = 'SCHEDULED',
  LINKED = 'LINKED',
  BREACHED = 'BREACHED',
  SECURED = 'SECURED',
  COMPLETED = 'COMPLETED',
  SUBMITTED = 'SUBMITTED',
}

export enum PaymentTermsType {
  DEFAULT = 'DEFAULT',
  EOM = 'EOM',
}

export enum PaymentSecuredStatus {
  SECURED = 'SECURED',
  PARTIALLY_SECURED = 'PARTIALLY_SECURED',
  NOT_SECURED = 'NOT_SECURED',
  TRANSFERRED = 'TRANSFERRED',
}

export enum DefaultPaymentActionType {
  CLAIM = 'CLAIM',
  UPDATE = 'UPDATE',
  LINK = 'LINK',
  MARK_AS_PAID = 'MARK_AS_PAID',
  RELEASE = 'RELEASE',
  SECURE = 'SECURE',
  EDIT = 'EDIT',
}

export enum PaymentType {
  STANDARD = 'STANDARD',
  CUSTOM = 'CUSTOM',
}

export enum PaymentScheduleType {
  DOM = 'DOM', // day of month
  EOM = 'EOM', // end of month
}

export enum SecurityType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export type LinkablePayment = {
  id: string;
  amount: number;
  stage: PaymentStageName;
  percentOfContractSum: string;
  description?: string;
  customName?: string;
  linkStatus: {
    linkable: boolean;
    statusCode: PaymentLinkStatus;
    errMessage: string;
  };
};

export enum PaymentLinkStatus {
  PAYMENT_ALREADY_CLAIMED = 'PAYMENT_ALREADY_CLAIMED',
  PAYMENT_NOT_SCHEDULED = 'PAYMENT_NOT_SCHEDULED',
  PAYMENT_ALREADY_LINKED = 'PAYMENT_ALREADY_LINKED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  PAYMENT_FULLY_SECURED = 'PAYMENT_FULLY_SECURED',
  LINKABLE = '',
}

export const paymentLinkStatusText = {
  [PaymentLinkStatus.PAYMENT_ALREADY_CLAIMED]: 'Already claimed',
  [PaymentLinkStatus.PAYMENT_NOT_SCHEDULED]: 'Not scheduled',
  [PaymentLinkStatus.PAYMENT_ALREADY_LINKED]: 'Already linked',
  [PaymentLinkStatus.INSUFFICIENT_FUNDS]: 'Insufficient funds',
  [PaymentLinkStatus.PAYMENT_FULLY_SECURED]: 'Already fully secured',
  [PaymentLinkStatus.LINKABLE]: '',
};

export const paymentLinkStatusTooltip = {
  [PaymentLinkStatus.PAYMENT_ALREADY_CLAIMED]:
    'A progress payment cannot be linked if already claimed.',
  [PaymentLinkStatus.PAYMENT_NOT_SCHEDULED]: 'A progress payment cannot be linked if not scheduled',
  [PaymentLinkStatus.PAYMENT_ALREADY_LINKED]:
    'A progress payment cannot be linked if already linked.',
  [PaymentLinkStatus.INSUFFICIENT_FUNDS]:
    'Insufficient funds in this progress payment to pay the linked claim.',
  [PaymentLinkStatus.PAYMENT_FULLY_SECURED]:
    'A progress payment cannot be linked if already fully secured.',
  [PaymentLinkStatus.LINKABLE]: '',
};
