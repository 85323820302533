import { forwardRef } from 'react';

export interface LogoProps extends React.SVGAttributes<SVGElement> {
  symbolOnly?: boolean;
}

export const Logo = forwardRef<SVGSVGElement, LogoProps>(({ symbolOnly, ...restProps }, ref) => {
  return symbolOnly ? (
    <svg ref={ref} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" {...restProps}>
      <title>Paid Inc.</title>
      <path fill="#00db8f" d="M17 59.5h28a28 28 0 0 1-28 28v-28Z" />
      <path fill="#0080ff" d="M39.2 30a6 6 0 0 1 5.8-4.5v-22A28 28 0 0 0 17 30Z" />
      <path fill="#ff0073" d="M39.2 33H17v26.5h28v-22a6 6 0 0 1-5.8-4.5Z" />
      <path fill="#00f3ff" d="M45 3.5v22a6 6 0 0 1 0 12v22a28 28 0 0 0 28-28 28 28 0 0 0-28-28Z" />
      <path fill="none" d="M0 0h90v90H0z" />
    </svg>
  ) : (
    <svg ref={ref} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267 90" {...restProps}>
      <title>Paid Inc.</title>
      <path fill="#00db8f" d="M0 59.5h28a28 28 0 0 1-28 28v-28Z" />
      <path fill="#0080ff" d="M22.2 30a6 6 0 0 1 5.8-4.5v-22A28 28 0 0 0 0 30Z" />
      <path fill="#ff0073" d="M22.2 33H0v26.5h28v-22a6 6 0 0 1-5.8-4.5Z" />
      <path fill="#00f3ff" d="M28 3.5v22a6 6 0 0 1 0 12v22a28 28 0 0 0 28-28 28 28 0 0 0-28-28Z" />
      <path fill="none" d="M0 0h267v90H0z" />
      <path
        fill="currentColor"
        d="M134.82 46.08c0-14.14 10-24.09 24.36-24.09 14.14 0 24 9.68 24 24.09v22.18h-7.94V58.4c-3 7.3-9.67 11-17.52 11-11.77 0-22.9-8.48-22.9-23.36m39.78-.36c0-9.13-6.2-15.7-15.51-15.7s-15.51 6.57-15.51 15.7 6.2 15.69 15.51 15.69 15.51-6.57 15.51-15.69M188.66 23.18h8.67v45.08h-8.67zM105 22c-14 0-24 9.4-24 24.09V87.5c1.09-.06 8.49-.68 8.63-7.17V60.32c3.28 5.84 9.49 9.12 16.79 9.12 11.77 0 22.9-8.48 22.9-23.45 0-14.14-10-24-24.36-24m.09 39.42c-9.22 0-15.42-6.57-15.42-15.69S95.87 30 105.09 30s15.51 6.57 15.51 15.7-6.21 15.69-15.51 15.69M251.13 10.67c-.14-6.48-7.54-7.11-8.65-7.17v27.62C239.19 25.28 233 22 225.69 22c-11.77 0-22.91 8.49-22.91 23.45 0 14.15 10 24 24.37 24 14 0 24-9.4 24-24.09V11a2.46 2.46 0 0 1 0-.28m-24.09 50.69c-9.31 0-15.51-6.57-15.51-15.69S217.75 30 227.06 30s15.42 6.57 15.42 15.7-6.21 15.69-15.42 15.69M199 9.5a6 6 0 1 1-6-6 6 6 0 0 1 6 6M267 63.57a6 6 0 1 1-6-6 6 6 0 0 1 6 6"
      />
    </svg>
  );
});

Logo.displayName = 'Logo';
